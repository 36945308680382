.Loader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loader div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.Loader div:nth-child(1) {
	left: 8px;
	animation: Loader1 0.6s infinite;
}
.Loader div:nth-child(2) {
	left: 8px;
	animation: Loader2 0.6s infinite;
}
.Loader div:nth-child(3) {
	left: 32px;
	animation: Loader2 0.6s infinite;
}
.Loader div:nth-child(4) {
	left: 56px;
	animation: Loader3 0.6s infinite;
}
@keyframes Loader1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes Loader3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes Loader2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
