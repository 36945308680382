@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: "Ubuntu", system-ui, sans-serif;
	}

	a {
		@apply text-primary;
	}
}

.leaflet-container a {
	@apply text-primary;
}

:focus-visible {
	outline: none;
}
